<template>
  <div>
    <mdb-modal
      ref="mdbModal"
      centered
      elegant
      scrollable
      size="lg"
      :show="showModal"
      @close="$emit('close', false);"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Agenda una cita
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body
        class="px-0 overflow-hidden"
      >
        <iframe
          :src="`${httpEndpoint}#${doctor.profileUrl
            ? doctor.profileUrl : '|'+doctor.id}`"
          frameborder="0"
          style="
            display: block;
            height: 520px;
            margin: auto;
          "
          scrolling="no"
          class="col-12"
        />
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import {mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody} from 'mdbvue';
export default {
  name: 'ModalAgendarCita',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    doctor: {
      default() {
        return {
          profileInfo: {
            acceptCallReq: null,
          },
          contactsInfo: [],
          works_at: [],
        };
      },
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      httpEndpoint: process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_RESERVA_HTTPS
  : 'http://localhost:8081',
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
