import { render, staticRenderFns } from "./formularioRegistro.vue?vue&type=template&id=410e7cca&"
import script from "./formularioRegistro.vue?vue&type=script&lang=js&"
export * from "./formularioRegistro.vue?vue&type=script&lang=js&"
import style0 from "./formularioRegistro.vue?vue&type=style&index=0&id=410e7cca&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports