import { render, staticRenderFns } from "./SelectIdentificationType.vue?vue&type=template&id=2389ad96&"
import script from "./SelectIdentificationType.vue?vue&type=script&lang=js&"
export * from "./SelectIdentificationType.vue?vue&type=script&lang=js&"
import style0 from "./SelectIdentificationType.vue?vue&type=style&index=0&id=2389ad96&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports