var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('header',{staticClass:"h2-responsive text-center mb-2 mt-4 pt-5"},[_vm._v(" ¡Regístrate hoy! ")]),_vm._m(0),_c('div',{staticClass:"card mx-auto mb-5 col-11 col-md-10 col-lg-11 col-xl-9 py-4 px-3 px-sm-4 overflow-x-hidden"},[_c('header',{staticClass:"h4-responsive"},[_vm._v(" Formulario de registro para médicos ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('transition',{attrs:{"mode":"out-in","name":"fading-classes-transition","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOutLeft"}},[(_vm.step !== 5)?_c('ul',{key:"statusStepper",staticClass:"list-unstyled d-flex justify-content-center"},_vm._l((_vm.steps-1),function(i){return _c('li',{key:i},[_c('span',{class:['step', {'actual' : i=== _vm.step },
                       {'bg-success-base': i < _vm.step}]})])}),0):_c('p',{key:"finish",staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"fa-5x my-4 animated rotateIn delay-1s success-text",attrs:{"icon":"check-circle"}})],1)]),_c('transition',{attrs:{"mode":"out-in","name":"fading-classes-transition","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOutLeft"}},[(_vm.step === 1)?_c('fieldset',{key:"step1",staticClass:"row"},[_c('legend',{staticClass:"h5-responsive col-12 my-2"},[_vm._v(" Información personal ")]),_c('p',{staticClass:"col-12 text-muted"},[_vm._v(" ¿Cómo te verán tus pacientes? ")]),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('mdb-input',{class:[
                'my-2',
                {'class-valid': _vm.validateValue.fname === 'valid'},
                {'class-invalid': _vm.validateValue.fname === 'invalid'}],attrs:{"type":"text","label":"Primer nombre","outline":""},model:{value:(_vm.newDoc.fname),callback:function ($$v) {_vm.$set(_vm.newDoc, "fname", $$v)},expression:"newDoc.fname"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Segundo nombre","outline":""},model:{value:(_vm.newDoc.mname),callback:function ($$v) {_vm.$set(_vm.newDoc, "mname", $$v)},expression:"newDoc.mname"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('mdb-input',{class:[
                'my-2',
                {'class-valid': _vm.validateValue.lname === 'valid'},
                {'class-invalid': _vm.validateValue.lname === 'invalid'}],attrs:{"type":"text","label":"Primer apellido","outline":""},model:{value:(_vm.newDoc.lname),callback:function ($$v) {_vm.$set(_vm.newDoc, "lname", $$v)},expression:"newDoc.lname"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Segundo apellido","outline":""},model:{value:(_vm.newDoc.llname),callback:function ($$v) {_vm.$set(_vm.newDoc, "llname", $$v)},expression:"newDoc.llname"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('SelectGender',{attrs:{"class-name":"md-outline outline-select my-2","name-select":"newDoc","validate-value":_vm.validateValue.gender},model:{value:(_vm.newDoc.gender),callback:function ($$v) {_vm.$set(_vm.newDoc, "gender", $$v)},expression:"newDoc.gender"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('mdb-input',{class:[
                'my-2 outline-date',
                {'class-valid': _vm.validateValue.born === 'valid'},
                {'class-invalid': _vm.validateValue.born === 'invalid'}],attrs:{"type":"date","label":"F de nacimiento","outline":""},model:{value:(_vm.newDoc.born),callback:function ($$v) {_vm.$set(_vm.newDoc, "born", $$v)},expression:"newDoc.born"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('SelectIdentificationType',{ref:"componentSelectIdentificationtype",attrs:{"class-name":"md-outline outline-select my-2","validate-value":_vm.validateValue},on:{"save":function($event){_vm.identificationType=$event;
                     _vm.newDocIdentificationInitialValue()}},model:{value:(_vm.newDoc.identificationType),callback:function ($$v) {_vm.$set(_vm.newDoc, "identificationType", $$v)},expression:"newDoc.identificationType"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('mdb-input',{ref:"inputIdentification",class:[
                'my-2',
                {'class-valid': _vm.validateValue.identification === 'valid'},
                {'class-invalid':
                  _vm.validateValue.identification === 'invalid'}],attrs:{"type":"text","label":"Identificación","outline":""},model:{value:(_vm.newDoc.identification),callback:function ($$v) {_vm.$set(_vm.newDoc, "identification", $$v)},expression:"newDoc.identification"}})],1)]):_vm._e(),(_vm.step === 2)?_c('fieldset',{key:"step2",staticClass:"row"},[_c('legend',{staticClass:"h5-responsive col-12 my-2"},[_vm._v(" Información de contacto ")]),_c('p',{staticClass:"col-12 text-muted"},[_vm._v(" Para recibir notificaciones importantes de tu cuenta AMII ")]),_c('div',{staticClass:"col-sm-5 col-lg-6"},[_c('mdb-input',{class:[
                'my-2',
                {'class-valid': _vm.validateValue.email === 'valid'},
                {'class-invalid': _vm.validateValue.email === 'invalid'}],attrs:{"type":"email","label":"Email principal","outline":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"col-sm d-flex px-0"},[_c('div',{staticClass:"col-4 pr-0"},[_c('CustomDatalist',{attrs:{"id":"telCode","list":_vm.telCodes,"class-name":['my-2',
                              {'class-valid':
                                _vm.validateValue.telCode == 'valid'},
                              {'class-invalid':
                                _vm.validateValue.telCode == 'invalid'}],"label":"País","type":"number","outline":true},on:{"save":function($event){_vm.telCountry=$event.code;}},model:{value:(_vm.telCountry),callback:function ($$v) {_vm.telCountry=$$v},expression:"telCountry"}})],1),_c('div',{staticClass:"col pl-0"},[_c('mdb-input',{class:[
                  'my-2',
                  {'class-valid': _vm.validateValue.tel === 'valid'},
                  {'class-invalid': _vm.validateValue.tel === 'invalid'}],attrs:{"type":"tel","label":"Teléfono principal","outline":""},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)])]):_vm._e(),(_vm.step === 3)?_c('fieldset',{key:"step3",staticClass:"row"},[_c('legend',{staticClass:"h5-responsive col-12 my-2"},[_vm._v(" Información profesional ")]),_c('p',{staticClass:"col-12 text-muted"},[_vm._v(" Cuéntanos tu especialidad, "),_c('span',{staticClass:"font-italic"},[_vm._v(" ¿Eres médico general, pediatra, obstetra,...? ")]),_c('small',{staticClass:"d-block text-muted"},[_vm._v(" Validamos esta información para evitar ofrecer a los pacientes falsos profesionales en nuestra plataforma. ")])]),_c('div',{staticClass:"col-sm"},[_c('mdb-input',{class:[
                'my-2',
                {'class-valid': _vm.validateValue.degreeName === 'valid'},
                {'class-invalid': _vm.validateValue.degreeName === 'invalid'}
              ],attrs:{"type":"text","label":"Título Obtenido","outline":""},model:{value:(_vm.degree.name),callback:function ($$v) {_vm.$set(_vm.degree, "name", $$v)},expression:"degree.name"}})],1),_c('div',{staticClass:"col-sm input-group"},[_c('mdb-input',{class:[
                'col p-0 my-2',
                {'class-valid': _vm.validateValue.degreeCode === 'valid'},
                {'class-invalid': _vm.validateValue.degreeCode === 'invalid'}],attrs:{"type":"text","label":"Matrícula M.P.P.S.","outline":""},model:{value:(_vm.degree.code),callback:function ($$v) {_vm.$set(_vm.degree, "code", $$v)},expression:"degree.code"}}),_c('mdb-tooltip',{staticClass:"input-group-text md-addon mb-2",attrs:{"trigger":"hover","options":{placement: 'left'}}},[_c('div',{attrs:{"slot":"tip"},slot:"tip"},[_c('p',{staticClass:"mb-0"},[_vm._v(" La matrícula M.P.P.S. es el número de licencia que te autoriza como médico en Venezuela. Coloca tu número de licencia según tu país. ")])]),_c('a',{attrs:{"slot":"reference"},slot:"reference"},[_c('font-awesome-icon',{staticClass:"d-inline",attrs:{"icon":['fas', 'question-circle'],"size":"sm"}})],1)])],1)]):_vm._e(),(_vm.step === 4)?_c('fieldset',{key:"step4",staticClass:"row justify-content-center"},[_c('legend',{staticClass:"h5-responsive col-12 my-2 text-center"},[_vm._v(" Elige tu contraseña AMII ")]),_c('div',[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Usuario","disabled":"","outline":""},model:{value:(_vm.newDoc.identification),callback:function ($$v) {_vm.$set(_vm.newDoc, "identification", $$v)},expression:"newDoc.identification"}})],1),_c('div',{staticClass:"col-sm-6 col-lg-4"},[_c('mdb-input',{class:[
                'my-2',
                {'class-valid': _vm.validateValue.password === 'valid'},
                {'class-invalid': _vm.validateValue.password === 'invalid'}
              ],attrs:{"type":"password","label":"Contraseña","outline":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]):_vm._e(),(_vm.step === 5)?_c('fieldset',{key:"step5",staticClass:"text-center"},[_c('legend',{staticClass:"h4-responsive my-2 text-center"},[_vm._v(" ¡Felicidades! ")]),_c('div',{staticClass:"mb-3"},[_c('p',[_vm._v(" Ya casi eres un médico AMII, revisa en tu bandeja de "),_c('span',{staticClass:"d-sm-block"},[_vm._v(" entrada del correo "+_vm._s(_vm.email)+" en busca del mensaje de validación de AMII. ")])]),_c('p',[_vm._v(" Abre el mensaje y pulsa el botón Validar correo, eso "),_c('span',{staticClass:"d-sm-block"},[_vm._v(" te llevará a tu inicio de sesión como Profesional AMII. ")])]),_c('small',[_vm._v(" Si ya validaste tu correo, puedes iniciar "),_c('span',{staticClass:"d-sm-block fs-inherit"},[_vm._v(" sesión haciendo clic en el siguiente botón: ")])])]),_c('a',{attrs:{"href":"https://medico.amii.app","target":"_self","rel":"noopener noreferrer"}},[_c('mdb-btn',{attrs:{"color":"primary-base","icon":"user"}},[_vm._v(" Iniciar sesión ")])],1)]):_vm._e()]),(_vm.step === 4)?_c('p',{staticClass:"mb-0 mt-2 text-center animated fadeInUp delay-1s"},[_c('span',{class:['py-2 px-3 fake-checkbox',
                   {'active': _vm.checkTerm}],on:{"click":function($event){return _vm.$emit('show-terms')}}},[_vm._v(" Acepto los términos y condiciones ")])]):_vm._e(),_c('div',{staticClass:"card-buttons mt-3"},[(_vm.step < _vm.steps-1)?_c('div',[_c('mdb-btn',{attrs:{"color":"primary-base","icon":"angle-right","icon-right":""},on:{"click":_vm.next}},[_vm._v(" Siguiente ")])],1):(_vm.step !== 5)?_c('div',[_c('BtnLoader',{ref:"btnDoctorSignup",attrs:{"btn-classes":"col-auto","btn-color":"success-base","btn-disabled":_vm.registerBtnDisabled,"btn-title":"Registrarse"},on:{"click":_vm.next}})],1):_vm._e(),(_vm.step > 1)?_c('div',[_c('mdb-btn',{staticClass:"flat-bordered",attrs:{"flat":"","dark-waves":"","icon":"angle-left"},on:{"click":function($event){_vm.step === 5 ? _vm.step = 1: _vm.step--}}},[_vm._v(" "+_vm._s(_vm.step === 5 ? 'Volver al formulario': 'Regresar')+" ")])],1):_vm._e()]),_c('div',{staticClass:"row mt-3"},[_c('div',{ref:"divMostrarCupon",staticClass:"col-md-6 justify-aligning-center text-muted cursor-pointer",on:{"click":function($event){_vm.mostrarCupon = !_vm.mostrarCupon}}},[_vm._m(1)]),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animated headShake","leave-active-class":"animated bounceOut"},on:{"after-enter":function($event){return _vm.$refs.cuponInput.focus()}}},[(_vm.mostrarCupon)?_c('div',{staticClass:"col-md-6 justify-aligning-center"},[_c('mdb-input',{ref:"cuponInput",class:[
                'my-2',
                {'class-valid': _vm.validateValue.cupon === 'valid'},
                {'class-invalid': _vm.validateValue.cupon === 'invalid'}],attrs:{"type":"text","label":"Código","outline":""},model:{value:(_vm.cupon),callback:function ($$v) {_vm.cupon=$$v},expression:"cupon"}})],1):_vm._e()])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"lead text-center text-muted font-italic mb-5"},[_vm._v(" Y sé parte de nuestra comunidad de médicos a la vanguardia de "),_c('span',{staticClass:"font-weight-bold primary-dark-text"},[_vm._v("AMII")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ¿Tienes un código de invitación? "),_c('br'),_vm._v(" ¡Agrégalo "),_c('u',[_vm._v("aquí")]),_vm._v(" y obtén descuento! ")])
}]

export { render, staticRenderFns }